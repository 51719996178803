import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'




const IncusPassesSRRMDR = ({ data }) => {
  return (
    <Layout pageTitle="INCUS | Extreme Weather in the News">
      <h1>INCUS Passes KDP-B</h1>
      <h5>March 14, 2023</h5>

      <p>WASHINGTON, DC—The INCUS Key Decision Point-B was held at NASA HQ On March 14, 2023. Congratulations to the INCUS team for successfully passing the KDP-B and moving the INCUS mission into <Link to="/timeline">Phase B.</Link> </p>

      <p>The purpose of Phase B is for the project team to complete the technology development, engineering prototyping, heritage hardware and software assessments, and other risk-mitigation activities identified in the project Formulation Agreement (FA) and the preliminary design. The project demonstrates that its planning, technical, cost, and schedule baselines developed during Formulation are complete and consistent; that the preliminary design complies with its requirements; that the project is sufficiently mature to begin Phase C; and that the cost and schedule are adequate to enable mission success with acceptable risk. It is at the conclusion of this phase that the project and the Agency commit to accomplishing the project’s objectives for a given cost and schedule. (source: <a href="https://www.nasa.gov/seh/3-5-project-phase-b-preliminary-design-and-technology-completion" target="_blank">NASA</a>)</p>




    </Layout >

  )
}

export default IncusPassesSRRMDR

